import React, { FC, useEffect, useState } from 'react';
import App from '../App2';
import SignalingServerContext from './SignalingServer';
import { ConnectionManager } from './ConnectionManager';
import socketIo from "socket.io-client";
import { StreamComposerContext } from '../component/StreamComposer';
import ErrorBoundary from '../component/ErrorBoundary';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import CapabilityTester from '../containers/CapabilityTester';

const Root: FC<{}> = () => {
    const query = new URLSearchParams(useLocation().search);
    const signalingFromQuery = query.get('signaling');
    useEffect(() => {
        if (signalingFromQuery) {
            localStorage.setItem('signalingServerUrl', signalingFromQuery);
        }
    }, [ signalingFromQuery ]);
    const [ signalingServerUrl, setSignalingServerUrl ] = useState<string>(signalingFromQuery ?? localStorage.getItem('signalingServerUrl') ?? 'http://localhost:3000');
    const [ connectionManager, setConnectionManager ] = useState<ConnectionManager | null>(null);
    const [ composerStream, setComposerStream ] = useState<MediaStream>();
    useEffect(() => {
        console.log(signalingServerUrl);
        if (signalingServerUrl) {
            const socket = socketIo(signalingServerUrl);
            const newConnectionManager = new ConnectionManager(socket);
            setConnectionManager(newConnectionManager);
            return () => {
                newConnectionManager.cleanup();
                socket.close();
            };
        }
    }, [ signalingServerUrl ]);
    const setAndRememberSignalingServerUrl = (url: string) => {
        console.log('setSignalingServerUrl');
        setSignalingServerUrl(url);
        localStorage.setItem('signalingServerUrl', url);
    };
    const context = {
        signalingServerUrl,
        setSignalingServerUrl: setAndRememberSignalingServerUrl,
        connectionManager,
    };
    const composerContext = {
        stream: composerStream,
        setStream: setComposerStream,
    };
    return (
        <Switch>
            <Route path="/capabilities">
                <CapabilityTester/>
            </Route>
            <Route path="/">
                <ErrorBoundary>
                    <SignalingServerContext.Provider value={ context }>
                        <StreamComposerContext.Provider value={ composerContext }>
                            <App/>
                        </StreamComposerContext.Provider>
                    </SignalingServerContext.Provider>
                </ErrorBoundary>
            </Route>
        </Switch>
    );
};

export default Root;
