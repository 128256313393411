import { DEFAULT_CONSTRAINTS, DEFAULT_RTCOFFER_OPTIONS } from './constants';
import { EventEmitter } from 'events';
import adapter from 'webrtc-adapter';
import { maybePreferVideoReceiveCodec, maybeRemoveVideoFec, setCodecParam } from './SdpUtils';

type ConnectionRole = 'pending' | 'offer' | 'answer';

export class ConnectionManager extends EventEmitter {
    public readonly signalingServer: SocketIOClient.Socket;
    private createOfferLock: [ Promise<boolean>, (lockValue: boolean) => void ] = [ Promise.resolve(true), () => {
    } ];
    public readonly pc: RTCPeerConnection;
    connectionState: string = 'idle';
    private sessionId?: string;
    private user?: string;
    public role: ConnectionRole = 'pending';
    private localStream?: MediaStream;
    private composerStream?: MediaStream;
    private pcListeners: any[] = [];
    private messageQueue: any[] = [];
    private hasRemoteSdp: boolean = false;
    private queueProcessing: boolean = false;
    private audioEnabled: boolean = true;
    private videoEnabled: boolean = true;

    private offerProcessingPromise: Promise<void> = Promise.resolve();
    private screenShare?: MediaStream;

    constructor(signalingServer: SocketIOClient.Socket) {
        super();
        this.signalingServer = signalingServer;
        this.pc = new RTCPeerConnection({
            iceServers: [ {
                urls: 'stun:stun.l.google.com:19302' // Google's public STUN server
            } ]
        });
        this.setupPcListeners();
        this.setupSignalingListeners();
    }

    private addPcListener(event: string, listener: any) {
        this.pc.addEventListener(event, listener);
    }

    public muteTracks(audioEnabled: boolean, videoEnabled: boolean) {
        this.videoEnabled = videoEnabled;
        this.audioEnabled = audioEnabled;
        for (const track of this.localStream?.getTracks() ?? []) {
            if (track.kind === 'video') {
                track.enabled = this.videoEnabled;
            }
            if (track.kind === 'audio') {
                track.enabled = this.audioEnabled;
            }
        }
    }

    private setupPcListeners() {
        if (adapter.browserDetails.browser === 'edge') {
            const listener = (event: any) => {
                console.log('onaddstream', event);
                this.emit('remoteStream', event.stream)
            };
            this.addPcListener('addstream', listener);
        } else {
            const listener = (event: RTCTrackEvent) => {
                const transceivers = this.pc.getTransceivers();
                console.log('onTrack', event, transceivers);
                if (transceivers.length === 3 && event.transceiver.mid === transceivers[2].mid) {
                    console.log('emit: remoteScreenShareStream');
                    this.emit('remoteScreenShareStream', new MediaStream([event.receiver.track]));
                } else {
                    console.log('emit: remoteStream');
                    this.emit('remoteStream', event.streams[0]);
                }
            };
            this.addPcListener('track', listener);
        }
        this.addPcListener('icecandidate', this.onIceCandidate);
        this.addPcListener('negotiationneeded', this.onNegotiationNeeded);
        this.addPcListener('signalingstatechange', () => {
            console.log('signaling: ', this.pc.signalingState)
        });
    }

    private setupSignalingListeners() {
        this.signalingServer.on('connect', () => {
            // this.signalingServer!.on('joined', this.onUserJoined);
            this.emit('signaling-connect');
            this.signalingServer!.on('ice', async (payload: any) => {
                if (this.role !== 'pending') {
                    console.log('got ice', payload);
                    this.messageQueue.push({
                        type: 'ice',
                        payload,
                    });
                    this.drainQueue();
                }
            });
            this.signalingServer!.on('sdp', async (payload: any) => {
                if (this.role !== 'pending') {
                    this.messageQueue.unshift({
                        type: 'sdp',
                        payload,
                    });
                    this.hasRemoteSdp = true;
                    this.drainQueue();
                }
            });
            this.signalingServer!.on('request-offer', async () => {
                await this.createOffer();
            });
        });
    }

    private async drainQueue() {
        if (!this.hasRemoteSdp || this.queueProcessing) {
            return
        }
        // this.queueProcessing = true;
        while (this.messageQueue.length) {
            const message = this.messageQueue.shift();
            const { type, payload } = message;
            console.log('draining...', type, payload);
            switch (type) {
                case 'ice':
                    await this.pc.addIceCandidate(new RTCIceCandidate(payload.candidate));
                    break;
                case 'sdp':
                    if (payload.sdp.type === 'offer') {
                        if (this.pc.signalingState === 'stable') {
                            await this.handleRemoteDescription(payload.sdp);
                            const localDescription = await this.pc.createAnswer();
                            await this.handleLocalDescription(localDescription);
                        } else {
                            // TODO: add trace
                            console.log('invalid state');
                        }
                    } else if (payload.sdp.type === 'answer') {
                        if (this.pc.signalingState === 'have-local-offer') {
                            await this.handleRemoteDescription(payload.sdp);
                        } else {
                            // TODO: add trace
                            console.log('invalid state');
                        }
                    }
                    break;
            }
        }
        // this.hasRemoteSdp = false;
        this.queueProcessing = false;
    }

    private async handleRemoteDescription(payload: any) {
        payload.sdp = setCodecParam(payload.sdp, 'opus/48000', 'stereo', '1');
        payload.sdp = setCodecParam(payload.sdp, 'opus/48000', 'sprop-stereo', '1');
        payload.sdp = setCodecParam(payload.sdp, 'opus/48000', 'maxaveragebitrate', '510000');
        await this.pc.setRemoteDescription(new RTCSessionDescription(payload));
    }

    private async  handleLocalDescription(desc: RTCSessionDescriptionInit) {

        desc.sdp = setCodecParam(desc.sdp, 'opus/48000', 'stereo', '1');
        desc.sdp = setCodecParam(desc.sdp, 'opus/48000', 'sprop-stereo', '1');
        desc.sdp = setCodecParam(desc.sdp, 'opus/48000', 'maxaveragebitrate', '510000');
        // if (adapter.browserDetails.browser !== 'safari') {
        //     desc.sdp = maybePreferVideoReceiveCodec(desc.sdp, 'VP9');
        // }
        // desc.sdp = maybeRemoveVideoFec(desc.sdp, {videoFec: false});

        await this.pc.setLocalDescription(desc);
        await this.sendAnswerToPeer();
    }

    private async sendAnswerToPeer() {
        if(this.pc.localDescription?.type === 'answer' && !this.pc.canTrickleIceCandidates) {
            console.log('this.pc.canTrickleIceCandidates', this.pc.canTrickleIceCandidates);
            const gatheringPromise = new Promise(resolve => {
                this.pc.addEventListener('icegatheringstatechange', e => {
                    if ((e.target as RTCPeerConnection).iceGatheringState === 'complete') {
                        resolve(this.pc.localDescription);
                    }
                });
            });
            await gatheringPromise;
        }
        this.signalingServer!.emit('sdp', {
            sid: this.sessionId,
            sdp: this.pc.localDescription,
            user: this.user
        });
    }

    private async acquireOfferLock(forceRelease: boolean = false) {
        const [ value, release ] = this.createOfferLock;
        if (forceRelease) {
            release(false);
        }
        const result = {
            value: await value,
            release
        };
        this.createOfferLock = this.createNewOfferLock();
        return result;
    }

    private createNewOfferLock(): [ Promise<boolean>, (releaseValue: boolean) => void ] {
        let release: (releaseValue: boolean) => void;
        const result: Promise<boolean> = new Promise((resolve, reject) => {
            release = resolve;
        });
        return [ result, release! ];
    }

    private async createOffer(options: RTCOfferOptions = DEFAULT_RTCOFFER_OPTIONS) {
        const { pc } = this;
        const lock = await this.acquireOfferLock(true);
        if (pc.signalingState === 'stable' && lock.value) {
            try {
                console.log('Lock acquired, sending new offer');
                const localDescription = await pc.createOffer(options);
                console.log(localDescription.sdp!.includes('\r\na=ice-options:trickle'));
                await this.handleLocalDescription(localDescription);
                return true;
            } catch (e) {
                //InvalidStateError NotReadableError OperationError
                //InvalidStateError InvalidSessionDescriptionError
                throw e;
            }
        }
        const localDescription = await this.pc.createOffer();
        return this.handleLocalDescription(localDescription);
    }

    private onNegotiationNeeded = async () => {
        console.log('onNegotiationNeeded: ', this.role);
        if (this.role === 'offer') {
            await this.createOffer();
        } else if (this.role === 'answer' && this.pc.connectionState === 'connected') {
            await this.createOffer()
            // this.signalingServer?.emit('request-offer', { sid: this.sessionId, user: this.user });
        }
    };

    private onIceCandidate = (event: RTCPeerConnectionIceEvent) => {
        const { sessionId, user } = this;
        if (event.candidate) {
            this.signalingServer!.emit('candidate', { sid: sessionId, user, candidate: event.candidate });
        }
    };

    cleanup() {
        while (this.pcListeners.length) {
            const [ event, listener ] = this.pcListeners.pop();
            this.pc.removeEventListener(event, listener);
        }
    }

    isStarted() {
        return this.connectionState !== 'idle';
    }

    private promisedEmit = (event: string, ...args: any[]): Promise<any> => {
        const originalAck = args[args.length - 1];

        return new Promise((resolve, reject) => {
            //TODO: error handling
            if (typeof originalAck === 'function') {
                this.signalingServer!.emit(event, ...args, (response: any) => {
                    originalAck(response);
                    resolve(response);
                });
            } else {
                this.signalingServer!.emit(event, ...args, resolve);
            }

        });
    }

    async getLocalStream(streamConstraints: MediaStreamConstraints = DEFAULT_CONSTRAINTS): Promise<MediaStream> {
        return navigator.mediaDevices.getUserMedia(streamConstraints);
    }

    async getScreenshare(): Promise<MediaStream> {
        // TODO: types
        return (navigator.mediaDevices as any).getDisplayMedia();
    }

    private initializeLocalStream = async (audioStream?: MediaStream) => {
        const { pc } = this;
        this.localStream = await this.getLocalStream();
        // this.screenShare = await this.getScreenshare();
        if (typeof pc.addTrack === 'function') {
            if (audioStream) {
                for (const track of this.localStream.getAudioTracks()) {
                    this.localStream.removeTrack(track);
                }
                for (const track of audioStream.getAudioTracks()) {
                    this.localStream.addTrack(track);
                }
            }
            for (const track of this.localStream.getTracks()) {
                pc.addTrack(track, this.localStream);
            }
            if(this.screenShare) {
                for (const track of this.screenShare.getVideoTracks()) {
                    pc.addTrack(track, this.screenShare);
                }
            }
        } else {
            // Edge
            (pc as any).addStream(this.localStream);
        }
        this.emit('localStream', this.localStream);
    };

    async applyConstraintsChrome(constraints: MediaStreamConstraints) {
        let resolveOfferProcessing: (value?: void | PromiseLike<void>) => void = () => {
        };
        this.offerProcessingPromise = new Promise((resolve, reject) => {
            resolveOfferProcessing = resolve;
        });
        const senders = this.pc.getSenders();
        const sendersMap = {
            audio: [],
            video: []
        } as { [key: string]: RTCRtpSender[] };
        for (const track of (this?.localStream?.getTracks() ?? [])) {
            const trackSenders = senders.filter(sender => {
                return sender?.track?.id === track.id
            });
            for (const sender of trackSenders) {
                sendersMap[track.kind].push(sender);
                await sender.replaceTrack(null);
                // this.pc.removeTrack(sender);
            }
            // if(track.label !== 'MediaStreamAudioDestinationNode') {
            this.localStream?.removeTrack(track);
            track.stop()
            // }
        }
        // 1: getUserMedia with new constraints
        this.localStream = await this.getLocalStream(constraints);
        for (const track of this.localStream.getTracks()) {
            await sendersMap[track.kind][0].replaceTrack(track);
        }
        this.emit('localStream', this.localStream);
        resolveOfferProcessing();
    }

    async applyConstraints(constraints: MediaStreamConstraints) {
        if (false && adapter.browserDetails.browser === 'chrome') {
            await this.applyConstraintsChrome(constraints);
        } else {
            const audioPromiseArray = (this.localStream?.getAudioTracks() ?? []).map(track => {
                if (typeof constraints.audio === 'boolean') {
                    return Promise.resolve();
                }
                return track.applyConstraints(constraints.audio);
            });
            const videoPromiseArray = (this.localStream?.getVideoTracks() ?? []).map(track => {
                if (typeof constraints.video === 'boolean') {
                    return Promise.resolve();
                }
                return track.applyConstraints(constraints.video);
            });
            await Promise.all(audioPromiseArray);
            await Promise.all(videoPromiseArray);
        }
    }

    startCall = async (session: string, user: string, audioStream?: MediaStream) => {
        this.sessionId = session;
        this.user = user;
        this.connectionState = 'joining';
        const response = await this.promisedEmit('join-session', { sid: this.sessionId, user: this.user });
        this.role = response.role;
        this.connectionState = 'waiting';
        this.composerStream = audioStream;
        await this.initializeLocalStream(this.composerStream);
    }
}
