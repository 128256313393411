import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

interface StreamComposerContextType {
    stream?: MediaStream;
    setStream?: (stream: MediaStream) => void
};
export const StreamComposerContext = React.createContext<StreamComposerContextType>({});
const StreamComposer: FC<{}> = () => {
    const channels = 2;
    const [ audioContext, setAudioContext ] = useState<AudioContext>();
    const [ channelMerger, setChannelMerger ] = useState<ChannelMergerNode>();
    const [ playing, setPlaying ] = useState<boolean[]>([]);
    const [ channelNodes, setChannelNodes ] = useState<OscillatorNode[]>([]);
    const [ channelRms, setChannelRms ] = useState<number[]>([]);
    const { setStream } = useContext(StreamComposerContext);
    useEffect(() => {
        const localAudioContext = new AudioContext();
        setAudioContext(localAudioContext);
        const merger = localAudioContext.createChannelMerger(2);
        setChannelMerger(merger);
        const processor = localAudioContext.createScriptProcessor(2048, channels, channels);
        merger.connect(processor);
        const mediaStreamDestination = localAudioContext.createMediaStreamDestination();
        if(typeof setStream === 'function') {
            setStream(mediaStreamDestination.stream);
        }
        merger.connect(mediaStreamDestination);
        processor.connect(mediaStreamDestination);
        processor.addEventListener('audioprocess', (event: AudioProcessingEvent) => {
            const rmsNew = [];
            for (let i = 0; i < event.inputBuffer.numberOfChannels; i++) {
                const input = event.inputBuffer.getChannelData(i);
                const total = input.reduce((acc, item) => acc + Math.abs(item), 0.0);
                rmsNew[i] = Math.sqrt(total / input.length);
            }
            setChannelRms(rmsNew);
        });
        return () => {
            localAudioContext.close()
        };
    }, []);

    const playChannel = (channel: number) => {
        if (audioContext && channelMerger) {
            if (playing[channel]) {
                channelNodes[channel].stop(0);
                channelNodes[channel].disconnect();
                const newState = [ ...playing ];
                newState[channel] = false;
                setPlaying(newState);
            } else {
                const newChannelNodes = [ ...channelNodes ];
                newChannelNodes[channel] = audioContext?.createOscillator();
                newChannelNodes[channel].connect(channelMerger, 0, channel)
                newChannelNodes[channel].start(0);
                setChannelNodes(newChannelNodes);
                const newState = [ ...playing ];
                newState[channel] = true;
                setPlaying(newState);
            }
        }
    };

    const childNodes = [];
    for (let i = 0; i < channels; i++) {
        childNodes.push(<button onClick={ () => playChannel(i) }>{ playing[i] ? 'Play' : 'Stop' } CH: { i }</button>)
    }
    return (
        <>
            { childNodes }
            {
                channelRms.map((rms, key) => {
                    return (
                        <div key={ key } style={ { background: 'gray', height: '20px', marginBottom: '20px' } }>
                            <div style={ {
                                height: '100%',
                                background: 'red',
                                width: `${ Math.round(rms * 100) }%`
                            } }></div>
                        </div>
                    )
                })
            }
        </>
    );
};
export default StreamComposer;
