import React, { FC, useState } from 'react';
function closeStream(stream: MediaStream) {
    for(const track of stream.getTracks()) {
        track.stop();
    }
}
const apiTests: [ string, Function ][] = [
    [
        'Window', () => {
        return window !== undefined;
    },
    ],
    [
        'Navigator', () => {
        return navigator !== undefined;
    },
    ],
    [
        'MediaDevices API 0', () => {
        return window.navigator.getUserMedia !== undefined;
    },
    ],
    [
        'MediaDevices API 1', () => {
        return window.navigator.mediaDevices !== undefined;
    },
    ],
    [
        'MediaDevices API 2', () => {
        return window.navigator.mediaDevices.getUserMedia !== undefined;
    },
    ],
    [
        'ICE Trickling', async () => {
        const pc = new RTCPeerConnection();
        const offer = await pc.createOffer({offerToReceiveAudio: true});
        const result = offer.sdp!.includes('\r\na=ice-options:trickle');
        pc.close();
        return result;
    },
    ],
    [
        'Audio Context',
        () => {
            return window.AudioContext !== undefined;
        }
    ],
    [
        'MediaDevices API: getUserMedia (video)', async () => {
        const stream = await window.navigator.mediaDevices.getUserMedia({
            video: true,
        });
        closeStream(stream);
        return true;
    },
    ],
    [
        'MediaDevices API: getUserMedia (audio)', async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
        });
        closeStream(stream);
        return true;
    },
    ],
    [
        'MediaDevices API: getUserMedia (both)', async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        });
        closeStream(stream);
        return true;
    },
    ]
];
const CapabilityTester: FC<{}> = () => {
    const [ testResults, setTestResults ] = useState<{ [key: string]: any }>({});
    const runTests = async () => {
        for (const test of apiTests) {
            try {
                testResults[test[0]] = await test[1]();
                setTestResults({ ...testResults });
            } catch (e) {
                testResults[test[0]] = false;
                console.log(e);
            }
        }
    };
    return (
        <>
            Capability Tester <button onClick={ runTests }>Run</button>
            { apiTests.map(test => (<div>{ test[0] } { testResults[test[0]] === undefined ? 'UNDEF' : testResults[test[0]] ? 'OK' : 'ERROR' }</div>)) }
        </>
    );
}

export default CapabilityTester;
