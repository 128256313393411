import React from 'react';
import { ConnectionManager } from './ConnectionManager';
interface SignalingServerType {
    signalingServerUrl: string;
    connectionManager: ConnectionManager | null;
    setSignalingServerUrl: (signalingServerUrl: string) => void;
};
const SignalingServerContext = React.createContext<SignalingServerType>({
    signalingServerUrl: 'http://localhost:3000',
    connectionManager: null,
    setSignalingServerUrl: () => {}
});

export default SignalingServerContext;
