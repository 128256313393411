import { FC, useEffect, useState } from 'react';
import React from 'react';
import { Grid } from '@material-ui/core';
import { ConnectionManager } from '../util/ConnectionManager';
interface Props {
    connectionManager?: ConnectionManager | null;
}
const ConnectionManagerDebugger: FC<Props> = ({ connectionManager }) => {
    const [ , setForceRerender ] = useState();

    useEffect(() => {
        const refreshWidget = () => setForceRerender({});
        if (connectionManager?.pc) {
            connectionManager.pc.addEventListener('connectionstatechange', refreshWidget);
            connectionManager.pc.addEventListener('signalingstatechange', refreshWidget);
            connectionManager.pc.addEventListener('iceconnectionstatechange', refreshWidget);
            connectionManager.pc.addEventListener('icegatheringstatechange', refreshWidget);
            return () => {
                connectionManager.pc.removeEventListener('connectionstatechange', refreshWidget);
                connectionManager.pc.removeEventListener('signalingstatechange', refreshWidget);
                connectionManager.pc.removeEventListener('iceconnectionstatechange', refreshWidget);
                connectionManager.pc.removeEventListener('icegatheringstatechange', refreshWidget);
            };
        }
    }, [ connectionManager, connectionManager?.pc ]);
    return (
        <>
            Connection Manager:
            <Grid container>
                <Grid item xs>SessionState</Grid><Grid item xs>SignalingState</Grid><Grid item xs>ConnectionState</Grid><Grid item xs>IceGatheringState</Grid>
            </Grid>
            <Grid container>
                <Grid item xs>{ connectionManager?.connectionState }</Grid>
                <Grid item xs>{ connectionManager?.pc.signalingState }</Grid>
                <Grid item xs>{ connectionManager?.pc.connectionState }</Grid>
                <Grid item xs>{ connectionManager?.pc.iceGatheringState }</Grid>
            </Grid>
        </>
    );
};

export default ConnectionManagerDebugger;
