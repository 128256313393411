import React, { FC, useState } from 'react';
import { Grid } from '@material-ui/core';

function renderConstraint([ key, value ]: [ keyof MediaTrackSettings, boolean ], capabilities: MediaTrackCapabilities, constraints: MediaTrackConstraints) {
    return (
        <tr key={ key }>
            <td>{ key }</td>
            <td>{ JSON.stringify(value) }</td>
            <td>{ JSON.stringify(capabilities[key]) }</td>
            <td>{ JSON.stringify(constraints[key]) }</td>
        </tr>
    );
}

interface Props {
    stream?: MediaStream;
}

function renderTrack(track: MediaStreamTrack) {
    const settings = track.getSettings();
    const capabilities = track.getCapabilities ? track.getCapabilities() : {};
    const constraints = track.getConstraints();
    return (
        <Grid container direction={'column'}>
            <h4><strong>{ track.kind } {track.id} | {track.label} {track.readyState}</strong></h4>
            <table style={{border: '1px black solid'}}>
                <thead>
                    <th>Constraint</th>
                    <th>Effective value</th>
                    <th>Possible values</th>
                    <th>Applied constraints</th>
                </thead>
                <tbody>
                { Object.entries(settings).map(setting => renderConstraint(setting as any, capabilities, constraints)) }
                </tbody>
            </table>
        </Grid>

    );
}

const Constraints: FC<Props> = ({ stream }) => {
    const tracks = stream?.getTracks() ?? [];
    return (
        <Grid container>
            { tracks.length === 0 && <Grid item xs>No active tracks yet</Grid> }
            { tracks && tracks.map(renderTrack) }
        </Grid>
    );
};

export default Constraints;
