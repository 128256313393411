import React, { FC, useEffect, useState } from 'react';

interface Props {
    stream: MediaStream;
}

const RmsAnalyser: FC<Props> = ({ stream }) => {
    const [ channelRms, setChannelRms ] = useState([ 0, 0, 0, 0 ]);
    useEffect(() => {
        if(window.AudioContext !== undefined && stream.getAudioTracks().length > 0) {
            const audioContext = new AudioContext();
            const processor = audioContext.createScriptProcessor(2048, 4, 4);
            const source = audioContext.createMediaStreamSource(stream);
            source.connect(processor);
            processor.connect(audioContext.destination);
            processor.addEventListener('audioprocess', (event: AudioProcessingEvent) => {
                const rmsNew = [];
                for (let i = 0; i < event.inputBuffer.numberOfChannels; i++) {
                    const input = event.inputBuffer.getChannelData(i);
                    const total = input.reduce((acc, item) => acc + Math.abs(item), 0.0);
                    rmsNew[i] = Math.sqrt(total / input.length);
                }
                setChannelRms(rmsNew);
            });
            return () => {
                processor.disconnect();
                source.disconnect();
                audioContext.close()
            };
        }
    }, [ stream ]);
    if(window.AudioContext === undefined) {
        return (
            <h5>RMS Analyser is not available, AudioContext not present</h5>
        )
    }
    return (
        <>
            {
                channelRms.map((rms, key) => {
                    return (
                        <div key={key} style={ { background: 'gray', height: '20px', marginBottom: '20px' } }>
                            <div style={ { height: '100%', background: 'red', width: `${ Math.round(rms * 100) }%` } }></div>
                        </div>
                    )
                })
            }
        </>
    );
};
export default RmsAnalyser;
