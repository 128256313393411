import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import { Grid } from '@material-ui/core';
import adapter from 'webrtc-adapter';
import Constraints from './component/Constraints';
import SignalingServerContext from './util/SignalingServer';
import { Alert } from '@material-ui/lab';
import ConnectionManagerDebugger from './component/ConnectionManagerDebugger';
import useStreamConstraints, { ActionType } from './hooks/useStreamConstraints';
import RmsAnalyser from './component/RmsAnalyser';
import StreamComposer, { StreamComposerContext } from './component/StreamComposer';
import { Link, NavLink } from 'react-router-dom';

const randomUsername = () => Math.floor(Math.random() * 0xFFFFFF).toString(16);
let messageQueue: any[] = [];
const App: FC<{}> = () => {
    const localVideo = useRef<HTMLVideoElement>(null);
    const remoteVideo = useRef<HTMLVideoElement>(null);
    const screenShare = useRef<HTMLVideoElement>(null);
    const { connectionManager, setSignalingServerUrl, signalingServerUrl } = useContext(SignalingServerContext);
    const [ user, setUser ] = useState(randomUsername());
    const [ session, setSession ] = useState(localStorage.getItem('sessionId') ?? 'test');
    const [ ownStream, setOwnStream ] = useState<MediaStream>();
    const [ remoteStream, setRemoteStream ] = useState<MediaStream>();
    const { constraints, dispatch, echoCancellation, autoGainControl, noiseSuppression } = useStreamConstraints();
    const [ soundEnabled, setSoundEnabled ] = useState(true);
    const [ videoEnabled, setVideoEnabled ] = useState(true);
    const { stream } = useContext(StreamComposerContext);

    const [ , setForceRender ] = useState();
    useEffect(() => {
        connectionManager?.on('signaling-connect', () => {
            setForceRender({});
        });
    }, [ connectionManager ]);
    useEffect(() => {
        connectionManager?.on('remoteScreenShareStream', (remoteScreenShare: MediaStream) => {
            if (screenShare.current) {
                screenShare.current.srcObject = remoteScreenShare;
            }
        });
        connectionManager?.on('remoteStream', (remoteStream: MediaStream) => {
            remoteStream.addEventListener('removetrack', () => {
                if (remoteVideo.current) {
                    remoteVideo.current.srcObject = null;
                    remoteVideo.current.srcObject = remoteStream;
                }
            });
            remoteStream.addEventListener('addtrack', () => {
                if (remoteVideo.current) {
                    remoteVideo.current.srcObject = null;
                    remoteVideo.current.srcObject = remoteStream;
                }
            });
            if (remoteVideo.current) {
                remoteVideo.current.srcObject = null;
                remoteVideo.current.srcObject = remoteStream;
                setRemoteStream(remoteStream);
            }
        });

    }, [ remoteVideo, connectionManager ]);

    useEffect(() => {
        connectionManager?.on('localStream', (localStream: MediaStream) => {
            if (localVideo.current) {
                localVideo.current.srcObject = localStream;
                setOwnStream(localStream);
            }
        });
    }, [ localVideo, connectionManager ]);
    //react bug, it
    useEffect(() => {
        if(localVideo && localVideo.current) {
            const { current: videoElement } = localVideo;
            videoElement.setAttribute('muted', '');
        }
    }, [localVideo.current])
    useEffect(() => {
        connectionManager?.applyConstraints(constraints);
    }, [ constraints ]);
    const handleSessionChange = (event: ChangeEvent<HTMLInputElement>) => {
        localStorage.setItem('sessionId', event.target.value);
        setSession(event.target.value);
    };
    const handleSignalingServerChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSignalingServerUrl(event.target.value);
    };
    const muteTracks = (inputAudioEnabled: boolean, inputVideoEnabled: boolean) => {
        setSoundEnabled(inputAudioEnabled);
        setVideoEnabled(inputVideoEnabled);
        connectionManager?.muteTracks(inputAudioEnabled, inputVideoEnabled);
    };
    const startCall = async () => {
        if (connectionManager) {
            try {
                await connectionManager.startCall(session, user, stream);
            } catch (e) {
                alert(e);
            }
        }
    };
const vProps = { defaultMuted: true };
    return (
        <div className="App">
            <NavLink to={'/capabilities'}>Capabilities test</NavLink>
            {/*<StreamComposer/>*/ }
            <h1>{ user } ({ connectionManager?.role })</h1>
            <hr/>
            Session ID: <input type='text' onChange={ handleSessionChange } value={ session }/>
            <hr/>
            { connectionManager?.signalingServer?.connected ?
                <Alert severity="success">Signaling Server Connected!</Alert> :
                <Alert severity="error">Signaling Server Disconnected!</Alert> }
            Signaling server <input type='text' onChange={ handleSignalingServerChange } value={ signalingServerUrl }/>
            <hr/>
            <ConnectionManagerDebugger connectionManager={ connectionManager }/>
            <hr/>
            <button onClick={ startCall } disabled={ connectionManager?.isStarted() }>Connect</button>
            {/*<button onClick={ setLowRes } disabled={ !connectionManager?.isStarted() }>Low res test</button>*/ }
            {/*<button onClick={ () => setNoiseCancelling(!noiseCancelling) }*/ }
            {/*        disabled={ !connectionManager?.isStarted() }>Set Noise Canceling*/ }
            {/*    To { noiseCancelling ? 'OFF' : 'ON' }</button>*/ }
            {/*<button onClick={ () => setGainControl(!gainControl) } disabled={ !connectionManager?.isStarted() }>Set AGC*/ }
            {/*    to { gainControl ? 'OFF' : 'ON' }</button>*/ }
            <select>
                <option></option>
            </select>
            <button onClick={ () => dispatch({ type: ActionType.SET_NOISE_CANCELLING, payload: !noiseSuppression }) }
                    disabled={ !connectionManager?.isStarted() }>Set Noise Canceling
                To { noiseSuppression ? 'OFF' : 'ON' }</button>
            <button onClick={ () => dispatch({ type: ActionType.SET_AGC, payload: !autoGainControl }) }
                    disabled={ !connectionManager?.isStarted() }>Set AGC
                To { autoGainControl ? 'OFF' : 'ON' }</button>
            <button onClick={ () => dispatch({ type: ActionType.SET_ECHO_CANCELLATION, payload: !echoCancellation }) }
                    disabled={ !connectionManager?.isStarted() }>Set Echo Canceling
                To { echoCancellation ? 'OFF' : 'ON' }</button>
            <button onClick={ () => muteTracks(!soundEnabled, videoEnabled) }
                    disabled={ !connectionManager?.isStarted() }>{ soundEnabled ? 'Pause' : 'Resume' } Sound
            </button>
            <button onClick={ () => muteTracks(soundEnabled, !videoEnabled) }
                    disabled={ !connectionManager?.isStarted() }>{ videoEnabled ? 'Pause' : 'Resume' } Video
            </button>
            <Grid container>
                <Grid item xs>
                    <video id="localVideo" autoPlay playsInline muted ref={ localVideo }/>
                </Grid>
                <Grid item xs>
                    <video id="remoteVideo" playsInline autoPlay ref={ remoteVideo }/>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    <video id="screenShare" autoPlay playsInline muted ref={ screenShare }/>
                </Grid>

            </Grid>
            <Constraints stream={ ownStream }/>
            <Constraints stream={ remoteStream }/>
            Local stream ({ ownStream?.id }):
            { ownStream && <RmsAnalyser stream={ ownStream }/> }
            Remote stream ({ remoteStream?.id }):
            { remoteStream && <RmsAnalyser stream={ remoteStream }/> }
        </div>
    );
}

export default App;
