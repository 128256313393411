import React, { FC, useEffect, useRef } from 'react';

const ErrorBoundary: FC<{}> = ({ children }) => {
    const logger = useRef<HTMLPreElement>(null);
    const r = () => {
        try {
            return (
                <>{ children }</>
            )
        } catch (e) {
            return e.toString();
        }
    };
    useEffect(() => {
        const listener = (event: PromiseRejectionEvent) => {
            if (logger.current) {
                logger.current.innerText += "\n" + event.reason;
            }
        };
        const listener2 = (event: ErrorEvent) => {
            if (logger.current) {
                logger.current.innerText += "\n" + JSON.stringify({
                    error: event.error,
                    colno: event.colno,
                    filename: event.filename,
                    lineno: event.lineno,
                    message: event.message,
                });
            }
        };
        window.addEventListener('error', listener2);
        window.addEventListener('unhandledrejection', listener);
        return () => {
            window.removeEventListener('unhandledrejection', listener);
            window.removeEventListener('error', listener2);
        };
    }, [logger.current]);
    return (
        <>
            <pre ref={ logger }/>
            { r() }
        </>
    );
}

export default ErrorBoundary
