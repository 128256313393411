export const DEFAULT_CONSTRAINTS: MediaStreamConstraints = {
    audio: {
        channelCount: {
            min: 1,
            ideal: 2,
        },
        echoCancellation: true,
        noiseSuppression: false,
        autoGainControl: false,
        // sampleRate: 48000
    },
    video: true,
};
export const DEFAULT_RTCOFFER_OPTIONS: RTCOfferOptions = {
    voiceActivityDetection: false
};
