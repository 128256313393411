import { useReducer } from 'react';
import { DEFAULT_CONSTRAINTS } from '../util/constants';

export enum ActionType {
    'SET_ECHO_CANCELLATION' = 'SET_ECHO_CANCELLATION',
    'SET_NOISE_CANCELLING' = 'SET_NOISE_CANCELLING',
    'SET_AGC' = 'SET_AGC',
};
type Action = { type: ActionType.SET_ECHO_CANCELLATION, payload: ConstrainBoolean } |
    { type: ActionType.SET_NOISE_CANCELLING, payload: ConstrainBoolean } |
    { type: ActionType.SET_AGC, payload: ConstrainBoolean };

const reducer = (state: MediaStreamConstraints, action: Action) => {
    switch (action.type) {
        case ActionType.SET_ECHO_CANCELLATION:
            return {
                ...state,
                audio: {
                    ...(typeof state.audio === 'object' ? state.audio : {}),
                    echoCancellation: action.payload
                },
            };
        case ActionType.SET_NOISE_CANCELLING:
            return {
                ...state,
                audio: {
                    ...(typeof state.audio === 'object' ? state.audio : {}),
                    noiseSuppression: action.payload
                },
            };
        case ActionType.SET_AGC:
            return {
                ...state,
                audio: {
                    ...(typeof state.audio === 'object' ? state.audio : {}),
                    autoGainControl: action.payload
                },
            }
    }
    return state;
};

const useStreamConstraints = () => {
    const [ constraints, dispatch ] = useReducer(reducer, DEFAULT_CONSTRAINTS);
    return {
        constraints,
        dispatch,
        echoCancellation: typeof constraints.audio === 'boolean' ? true : constraints.audio?.echoCancellation ?? true,
        autoGainControl: typeof constraints.audio === 'boolean' ? true : constraints.audio?.autoGainControl ?? true,
        noiseSuppression: typeof constraints.audio === 'boolean' ? true : constraints.audio?.noiseSuppression ?? true,
    };
};

export default useStreamConstraints;
